<template>
  <div class="order_success">
    <div class="success_header">
      <img src="@/assets/images/order-success.png" />
    </div>
    <div class="success_body">
      <img class="cover_img" src="@/assets/images/order-cover.png" />
      <div class="body_msg">
        <div id="qrcodea"></div>
        <span class="msg_tig">预约成功，请在指定时间内前往中心办理</span>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: "orderInstructions",
  components: {},
  data() {
    return {
      qrcodeObj: null,
    };
  },
  mounted() {
    this.getQrcode();
  },
  methods: {
    getQrcode() {
      const { id } = this.$route.query;
      if (this.qrcodeObj) {
        let qrcodeHtml = document.getElementById("qrcodea");
        qrcodeHtml.innerHTML = "";
      }
      this.qrcodeObj = new QRCode("qrcodea", {
        text: id,
        width: 120,
        height: 120,
        colorDark: "#000000",
        colorLight: "#ffffff",
        // correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order_success {
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .success_header {
    position: relative;
    height: auto;
    width: 100%;
    padding: 50px 0 100px;
    background-image: linear-gradient(to right, #a5e571, #58af47);
    border: none;
    text-align: center;
    img {
      width: 110px;
      height: auto;
    }
  }
  .success_body {
    position: relative;
    margin-top: -80px;
    overflow: hidden;
    .cover_img {
      position: absolute;
      width: 130%;
      left: -11%;
    }
    .body_msg{
      position: relative;
      padding-top: 80px;
      z-index: 200;
      display: flex;
      flex-direction: column;
      align-items: center;
      #qrcodea{
        // position: absolute;
        width: 120px;
        height: 120px;
        background-color: #fff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .msg_tig{
        padding-top: 20px;
        color: #333333;
        font-size: 14px;
      }
    }
  }
}
</style>